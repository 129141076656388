import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a238f38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "regulation" }
const _hoisted_2 = { class: "regulation__box" }
const _hoisted_3 = { class: "regulation__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_regulation_text = _resolveComponent("regulation-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.t("REGULATION.HEADER")), 1 /* TEXT */),
      _createVNode(_component_a_divider),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_regulation_text)
      ])
    ])
  ]))
}