
import RegulationText from "../components/RegulationText.vue";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export const RegulationComponent = defineComponent({
  components: { RegulationText },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});

export default RegulationComponent;
