import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6106551a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "registration" }
const _hoisted_2 = { class: "registration__header" }
const _hoisted_3 = { class: "registration__subheader" }
const _hoisted_4 = { class: "white-box" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "registration__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_phone_number = _resolveComponent("phone-number")!
  const _component_tags = _resolveComponent("tags")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          lg: { span: 19 },
          md: { span: 19 },
          sm: { span: 24 },
          xs: { span: 24 }
        }, {
          default: _withCtx(() => [
            _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.t("REGISTRATION.HEADER")), 1 /* TEXT */),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t(`REGISTRATION.SUB_HEADER`)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_a_form, {
      autocomplete: "off",
      ref: "registrationFormTemplate",
      rules: _ctx.rules,
      model: _ctx.model
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.general, (record, key, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _withDirectives(_createVNode(_component_a_row, {
                class: _normalizeClass(['white-box__row', 'registration__' + _ctx.kebabCase(key)])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    lg: 5,
                    md: 5,
                    sm: 5,
                    xs: 10,
                    class: "white-box__field-name"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.t(`REGISTRATION.${_ctx.snakeCase(key).toUpperCase()}`)), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode(_component_a_col, {
                    lg: { span: 13, offset: 1 },
                    md: { span: 13, offset: 1 },
                    sm: { span: 12, offset: 2 },
                    xs: { span: 12, offset: 2 },
                    class: "white-box__value"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: key }, {
                        default: _withCtx(() => [
                          (record.type === 'input')
                            ? (_openBlock(), _createBlock(_component_a_input, {
                                key: 0,
                                placeholder: 
                    _ctx.t(`REGISTRATION.${_ctx.snakeCase(key).toUpperCase()}`)
                  ,
                                onChange: record.onChange,
                                value: _ctx.model[key],
                                "onUpdate:value": ($event: any) => ((_ctx.model[key]) = $event)
                              }, null, 8 /* PROPS */, ["placeholder", "onChange", "value", "onUpdate:value"]))
                            : _createCommentVNode("v-if", true),
                          (record.type === 'phone')
                            ? (_openBlock(), _createBlock(_component_phone_number, {
                                key: 1,
                                placeholder: _ctx.t('PHONE_NUMBER.NUMBER'),
                                value: _ctx.model[key],
                                "onUpdate:value": ($event: any) => ((_ctx.model[key]) = $event)
                              }, null, 8 /* PROPS */, ["placeholder", "value", "onUpdate:value"]))
                            : _createCommentVNode("v-if", true),
                          (record.type === 'tags')
                            ? (_openBlock(), _createBlock(_component_tags, {
                                key: 2,
                                mode: "single",
                                "allow-empty": false,
                                "empty-text": _ctx.t('NO_DATA'),
                                value: _ctx.model[key],
                                "onUpdate:value": ($event: any) => ((_ctx.model[key]) = $event),
                                data: record.data,
                                onChange: record.onChange
                              }, null, 8 /* PROPS */, ["empty-text", "value", "onUpdate:value", "data", "onChange"]))
                            : _createCommentVNode("v-if", true),
                          (record.type === 'select')
                            ? (_openBlock(), _createBlock(_component_a_select, {
                                key: 3,
                                value: _ctx.model[key],
                                "onUpdate:value": ($event: any) => ((_ctx.model[key]) = $event),
                                options: record.data,
                                placeholder: 
                    _ctx.t(`REGISTRATION.${_ctx.snakeCase(key).toUpperCase()}`)
                  ,
                                "not-found-content": _ctx.t('NO_DATA')
                              }, null, 8 /* PROPS */, ["value", "onUpdate:value", "options", "placeholder", "not-found-content"]))
                            : _createCommentVNode("v-if", true),
                          (record.type === 'datepicker')
                            ? (_openBlock(), _createBlock(_component_a_date_picker, {
                                key: 4,
                                disabled: record.disabled,
                                value: _ctx.model[key],
                                "onUpdate:value": ($event: any) => ((_ctx.model[key]) = $event),
                                placeholder: "DD.MM.RRRR",
                                "allow-clear": false,
                                style: {"width":"100%"},
                                format: "DD.MM.YYYY",
                                "disabled-date": _ctx.dateRange
                              }, null, 8 /* PROPS */, ["disabled", "value", "onUpdate:value", "disabled-date"]))
                            : _createCommentVNode("v-if", true)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"]), [
                [_vShow, record.visible]
              ])
            ]))
          }), 128 /* KEYED_FRAGMENT */)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.agreements, (record, key, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _withDirectives(_createVNode(_component_a_row, {
                class: "white-box__row",
                key: index
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { name: key }, {
                    default: _withCtx(() => [
                      (record.type === 'checkbox')
                        ? (_openBlock(), _createBlock(_component_a_checkbox, {
                            key: 0,
                            checked: _ctx.model[key],
                            "onUpdate:checked": ($event: any) => ((_ctx.model[key]) = $event),
                            class: _normalizeClass([
                  'registration__agreement',
                  'registration__agreement--' + _ctx.kebabCase(key),
                ])
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", {
                                innerHTML: record.data
                              }, null, 8 /* PROPS */, _hoisted_5)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["checked", "onUpdate:checked", "class"]))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1536 /* NEED_PATCH, DYNAMIC_SLOTS */), [
                [_vShow, record.visible]
              ])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["rules", "model"]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_a_button, {
        disabled: !_ctx.valid,
        loading: _ctx.loading,
        class: "registration__submit",
        type: "primary",
        onClick: _ctx.submit
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("REGISTRATION.REGISTER")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "loading", "onClick"]),
      _createVNode(_component_a_button, {
        class: "registratio__cancel",
        type: "default",
        onClick: _ctx.goBack
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("REGISTRATION.CANCEL")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ])
  ]))
}